import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import AppContext from '../../AppContext';

const Sidebar = ({ projectId, ecProject }) => {
  const { pathname } = useLocation();
  const { theme, isTouchDevice } = useContext(AppContext);
  const [canSidebarExpand, setCanSidebarExpand] = useState(true);
  const expandSideBar = (expand) => (isTouchDevice ? setCanSidebarExpand(expand) : null);

  const [activeNavLink, setActiveNavLink] = useState(null);

  const handleNavClick = (path) => {
    setActiveNavLink(path);
  };

  useEffect(() => {
    const page = pathname.split('/')[1];
    setActiveNavLink(`/${page}`);
  }, []);

  return (
    <section
      onMouseOver={() => expandSideBar(true)}
      className={`sidebar-component theme-${theme} ${canSidebarExpand ? '' : 'collapse'}`}
    >
      <nav className="main-nav">
        <ul className="pages">
          <li>
            <NavLink
              title="mims"
              exact
              to={{
                pathname: `/mach/${projectId}/`,
              }}
              isActive={() => activeNavLink === '/mach'}
              onClick={() => handleNavClick('/mach')}
            >
              <span>
                MACH Information<br></br>Management System
              </span>
            </NavLink>
          </li>
          {ecProject && (
            <li>
              <NavLink
                title="iot-cloud"
                exact
                to={{
                  pathname: `/iot-cloud/${projectId}/`,
                }}
                isActive={() => activeNavLink === '/iot-cloud'}
                onClick={() => handleNavClick('/iot-cloud')}
                className={activeNavLink === 'iot-cloud' ? 'active-link' : ''}
              >
                <span>IoT Cloud</span>
              </NavLink>
            </li>
          )}

          <li>
            <NavLink
              title="overview"
              exact
              to={{
                pathname: `/overview/${projectId}/`,
              }}
              isActive={() => activeNavLink === '/overview'}
              onClick={() => handleNavClick('/overview')}
            >
              <span>Overview</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </section>
  );
};

export default Sidebar;
