import React from 'react';
import SimpleModal from '../../../SimpleModal';
import JSONEditor from '../../../JSONEditor';
import { getProjectSchema, projectUISchema } from './schemas/projectSchema';

const ProjectCreateModal = ({
  projectData,
  title,
  onSubmit,
  onCloseModal,
  transformErrors,
  children,
}) => {
  const schema = getProjectSchema();

  return (
    <SimpleModal
      onClose={onCloseModal}
      title={title}
      overlayCanClose={false}
    >
      <div className="create-site-content">
        <JSONEditor
          title="Project"
          formData={{ ...projectData }}
          showErrorList={false}
          schema={schema}
          uiSchema={projectUISchema}
          initialEditMode={true}
          showEditButton={false}
          saveButtonText={'Save'}
          cancelCallback={onCloseModal}
          onFormSubmit={({ formData }) => onSubmit(formData)}
          customTransformErrors={(errors) => transformErrors(errors)}
        />
        {children}
      </div>
    </SimpleModal>
  );
};

export default ProjectCreateModal;
