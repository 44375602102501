/* eslint-disable default-param-last */

import { createSelector } from 'reselect';

import { CLEAR_SITE_DATA } from './application';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const SET_SITES = 'dt/sites/SET_SITES';

const SET_CREATED_SITE = 'dt/sites/SET_CREATED_SITE';
const SET_ACTIVE_SITE = 'dt/sites/SET_ACTIVE_SITE';
const SET_ACTIVE_SITE_ID = 'dt/sites/SET_ACTIVE_SITE_ID';
const SET_ACTIVE_SITE_UIMODE = 'dt/sites/SET_ACTIVE_SITE_UIMODE';
export const REMOVE_SITE = 'dt/sites/REMOVE_SITE';

const RECEIVE_MODEL_THUMBNAIL = 'dt/models/RECEIVE_MODEL_THUMBNAIL';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const setActiveSite = (siteId) => ({
  type: SET_ACTIVE_SITE,
  siteId,
});

export const setActiveSiteId = (siteId) => ({
  type: SET_ACTIVE_SITE_ID,
  siteId,
});

export const setActiveUIMode = (uiMode) => ({
  type: SET_ACTIVE_SITE_UIMODE,
  uiMode,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  sites: [],
  projects: [],
  thumbnails: {},
  createdSite: null,
  activeSiteId: null,
  activeUiMode: 'Identiq',
  siteSetAt: null,
  sitesLoadedAt: null,
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SITES: {
      const { sites } = action;
      return {
        ...state,
        sites: sites.map(({ layouts = [], ...rest }) => ({
          ...rest,
          layout:
            layouts.length > 0
              ? layouts.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0]
              : {},
        })),
        sitesLoadedAt: Date.now(),
      };
    }
    case RECEIVE_MODEL_THUMBNAIL: {
      const { modelId, versionId, src } = action;
      const thumbnailKey = `${modelId}/${versionId}`;

      return {
        ...state,
        thumbnails: {
          ...state.thumbnails,
          [thumbnailKey]: src,
        },
      };
    }
    case SET_CREATED_SITE: {
      return { ...state, createdSite: action.createdSite };
    }

    case SET_ACTIVE_SITE_ID: {
      return {
        ...state,
        activeSiteId: action.siteId,
        siteSetAt: Date.now(),
      };
    }
    case SET_ACTIVE_SITE_UIMODE: {
      return { ...state, activeUiMode: action.uiMode };
    }
    case REMOVE_SITE: {
      return {
        ...state,
        sites: state.sites.filter((s) => s.id !== action.siteId),
      };
    }
    case CLEAR_SITE_DATA: {
      return {
        ...state,
        createdSite: null,
        createdProjects: null,
        activeSiteId: null,
        activeUiMode: 'Identiq',
        siteSetAt: null,
      };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getSites = (state) => state.sites.sites;

export const getSiteSetAt = (state) => state.sites.siteSetAt;

export const getActiveSiteId = (state) => state.sites.activeSiteId;

export const getActiveSite = createSelector([getActiveSiteId, getSites], (activeSiteId, sites) => {
  if (sites.length && activeSiteId) {
    return sites.find((site) => site.id === activeSiteId);
  }
  // return empty site obj if waiting on site data to diff from non-site
  return {};
});

export const getActiveUiMode = (state) => state.sites.activeUiMode;

export const sagas = [];
