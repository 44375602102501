/* eslint-disable default-param-last */
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import {
  getAllProjects as getAllProjectsApi,
  createMimsProject as createMimsProjectApi,
  createEcProject as createEcProjectApi,
  deleteProject as deleteProjectApi,
  downloadMimsConfig as downloadMimsConfigApi,
  downloadEcConfig as downloadEcConfigApi,
  fetchProject,
} from '../services';
import { displayNotification, checkOnline } from './notifications';
import { downloadBlob } from '../utils';
import getNotification from './notification-defaults';
import { CLEAR_SITE_DATA } from './application';
import { PROJECT_DELIVERY_TYPE } from '../constants';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const GET_PROJECTS = 'dt/projects/GET_PROJECTS';
export const RECEIVE_PROJECTS = 'dt/projects/RECEIVE_PROJECTS';
export const CREATE_MIMS_PROJECT = 'dt/projects/CREATE_MIMS_PROJECT';
export const CREATE_EC_PROJECT = 'dt/projects/CREATE_EC_PROJECT';
export const UPDATE_PROJECT = 'dt/projects/UPDATE_PROJECT';
export const DELETE_PROJECT = 'dt/projects/DELETE_PROJECT';
export const DOWNLOAD_MIMS_CONFIG = 'dt/projects/DOWNLOAD_MIMS_CONFIG';
export const DOWNLOAD_EC_CONFIG = 'dt/projects/DOWNLOAD_EC_CONFIG';
export const FETCH_PROJECT = 'dt/projects/FETCH_PROJECT';
export const FETCH_PROJECT_SUCCESS = 'dt/projects/FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAILURE = 'dt/projects/FETCH_PROJECT_FAILURE';
export const SET_NEWLY_CREATED_PROJECT_ID = 'dt/projects/SET_NEWLY_CREATED_PROJECT_ID';
export const RESET_NEWLY_CREATED_PROJECT_ID = 'dt/projects/SET_NEWLY_CREATED_PROJECT_ID';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const setNewlyCreatedProjectId = (id) => ({
  type: SET_NEWLY_CREATED_PROJECT_ID,
  id,
});

export const resetNewlyCreatedProjectId = () => ({
  type: RESET_NEWLY_CREATED_PROJECT_ID,
});

export const requestProjects = (callback) => ({
  type: GET_PROJECTS,
  callback,
});

export const updateProject = (project) => ({
  type: UPDATE_PROJECT,
  project,
});

export const deleteProject = (projectId, projectName) => ({
  type: DELETE_PROJECT,
  projectId,
  projectName,
});

export const receiveProjects = (projects) => ({
  type: RECEIVE_PROJECTS,
  projects,
});

export const createMimsProject = (project) => ({
  type: CREATE_MIMS_PROJECT,
  project,
});

export const createEcProject = (project) => ({
  type: CREATE_EC_PROJECT,
  project,
});

export const downloadMimsConfig = (project) => ({
  type: DOWNLOAD_MIMS_CONFIG,
  project,
});

export const downloadEcConfig = (project) => ({
  type: DOWNLOAD_EC_CONFIG,
  project,
});

export const fetchProjectSuccess = (data) => ({
  type: FETCH_PROJECT_SUCCESS,
  payload: data,
});

export const fetchProjectFailure = (error) => ({
  type: FETCH_PROJECT_FAILURE,
  payload: error,
});

export const fetchProjectAction = (id) => ({
  type: FETCH_PROJECT,
  payload: id,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  projects: [],
  projectMap: {},
  newlyCreatedProjectId: null,
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PROJECTS: {
      const { projects } = action;

      return { ...state, projects };
    }
    case SET_NEWLY_CREATED_PROJECT_ID: {
      return { ...state, newlyCreatedProjectId: action.id };
    }
    case RESET_NEWLY_CREATED_PROJECT_ID: {
      return { ...state, newlyCreatedProjectId: null };
    }
    case FETCH_PROJECT_SUCCESS: {
      return {
        ...state,
        projectMap: {
          ...state.projectMap,
          [action.payload.id]: action.payload,
        },
      };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return { ...state, projects: [], projectMap: {} };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getProjects = (state) => state.projects.projects;

export const getProjectsMap = (state) => state.projects.projectMap;

export const getProject = createSelector(
  getProjectsMap,
  (_, projectId) => projectId,
  (projectsMap, projectId) => projectsMap[projectId]
);

export const selectNewlyCreatedProjectId = (state) => state.projects.newlyCreatedProjectId;

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

export function* fetchProjectSaga({ payload: id }) {
  try {
    const data = yield fetchProject(id);
    yield put(fetchProjectSuccess(data));
  } catch (error) {
    yield put(fetchProjectFailure(error));
  }
}

export function* doRequestProjects() {
  try {
    const projects = yield call(getAllProjectsApi);
    projects.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    const oldProjects = yield select(getProjects);
    if (JSON.stringify(oldProjects) !== JSON.stringify(projects)) {
      yield put(receiveProjects(projects));
    }
  } catch (e) {
    yield console.error('Unable to fetch projects: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getProjects', 'error')()));
  }
}

export function* doCreateMimsProject({ project }) {
  try {
    const newProject = yield createMimsProjectApi(project);
    yield put(setNewlyCreatedProjectId(newProject.id));
    yield call(doRequestProjects);
    yield put(displayNotification(getNotification('createMimsProject', 'success')(project.name)));
  } catch (e) {
    console.error('Unable to create mims project: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createMimsProject', 'error')()));
  }
}

export function* doCreateEcProject({ project }) {
  try {
    const newProject = yield createEcProjectApi(project);
    yield put(setNewlyCreatedProjectId(newProject.id));
    yield call(doRequestProjects);
    yield put(displayNotification(getNotification('createEcProject', 'success')(project.name)));
  } catch (e) {
    console.error('Unable to create ec project: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createEcProject', 'error')()));
  }
}

export function* doUpdateProject({ project }) {
  try {
    yield call(deleteProjectApi, project.id);
    if (project.deliveryType === PROJECT_DELIVERY_TYPE.EC) {
      const newProject = yield call(createEcProjectApi, project);
      yield put(setNewlyCreatedProjectId(newProject.id));
    } else {
      const newProject = yield call(createMimsProjectApi, project);
      yield put(setNewlyCreatedProjectId(newProject.id));
    }
    yield call(doRequestProjects);
    yield put(displayNotification(getNotification('updateProject', 'success')(project.name)));
  } catch (e) {
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateProject', 'error')()));
  }
}

function* doDeleteProject(action) {
  const { projectId, projectName } = action;
  try {
    yield call(deleteProjectApi, projectId);
    yield call(doRequestProjects);
    yield put(displayNotification(getNotification('deleteProject', 'success')(projectName)));
  } catch (e) {
    console.error('Unable to delete project: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteProjects', 'error')(projectName)));
  }
}

function* doDownloadMimsConfig({ project }) {
  try {
    const file = yield call(downloadMimsConfigApi, project.id);
    downloadBlob(
      file.data,
      `${project.name.replace(/\s/g, '_')}_MIMS_config.zip`,
      file.headers['Content-Type']
    );
    yield put(displayNotification(getNotification('downloadMimsConfig', 'success')()));
  } catch (e) {
    console.error('Unable to download MIMS project: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('downloadMimsConfig', 'error')()));
  }
}

function* doDownloadEcConfig({ project }) {
  try {
    const file = yield call(downloadEcConfigApi, project.id);
    downloadBlob(
      file.data,
      `${project.name.replace(/\s/g, '_')}_EC_config.zip`,
      file.headers['Content-Type']
    );
    yield put(displayNotification(getNotification('downloadEcConfig', 'success')()));
  } catch (e) {
    console.error('Unable to download EC project: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('downloadEcConfig', 'error')()));
  }
}

export const sagas = [
  takeLatest(GET_PROJECTS, doRequestProjects),
  takeLatest(UPDATE_PROJECT, doUpdateProject),
  takeLatest(DELETE_PROJECT, doDeleteProject),
  takeLatest(CREATE_MIMS_PROJECT, doCreateMimsProject),
  takeLatest(CREATE_EC_PROJECT, doCreateEcProject),
  takeLatest(FETCH_PROJECT, fetchProjectSaga),
  takeLatest(DOWNLOAD_MIMS_CONFIG, doDownloadMimsConfig),
  takeLatest(DOWNLOAD_EC_CONFIG, doDownloadEcConfig),
];
