/* eslint-disable default-param-last */
import { debounce, takeLatest, select, put } from 'redux-saga/effects';

import { createSelector } from 'reselect';

import { updateUrlQuery } from './utils';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const SET_ACTIVE_PAGE_TYPE = 'dt/application/SET_ACTIVE_PAGE_TYPE';
export const SET_ACTIVE_COMPONENT_ID = 'dt/application/SET_ACTIVE_COMPONENT_ID';
export const SET_ACTIVE_COMPONENT_FILTER = 'dt/application/SET_ACTIVE_COMPONENT_FILTER';
export const SET_ACTIVE_MODEL = 'dt/application/SET_ACTIVE_MODEL';
export const SET_ZOOM_COMPONENT = 'dt/application/SET_ZOOM_COMPONENT';
export const SET_VIEWER_READY = 'dt/application/SET_VIEWER_READY';
export const SET_TIMEZONE = 'dt/application/SET_TIMEZONE';

export const RECEIVE_ACTIVE_COMPONENT = 'dt/application/RECEIVE_ACTIVE_COMPONENT';
export const RECEIVE_POLLING_DATE_RANGE = 'dt/application/RECEIVE_POLLING_DATE_RANGE';
export const RECEIVE_POLLING_INTERVAL = 'dt/application/RECEIVE_POLLING_INTERVAL';

export const REFRESH_VALUES = 'dt/application/REFRESH_VALUES';
export const RECEIVE_REFRESH = 'dt/application/RECEIVE_REFRESH';
export const CLEAR_SITE_DATA = 'dt/application/CLEAR_SITE_DATA';

export const INITIALIZE_POLLING = 'dt/application/INITIALIZE_POLLING';
export const START_POLLING = 'dt/application/START_POLLING';
export const STOP_POLLING = 'dt/application/STOP_POLLING';

export const SET_POLLING_ACTIVE = 'dt/application/IS_POLLING_ACTIVE';
export const SET_POLLING_ACTIVE_DONE = 'dt/application/IS_POLLING_ACTIVE_DONE';
export const CREATE_PANEL_DATE_SUBSCRIPTION = 'dt/application/CREATE_PANEL_DATE_SUBSCRIPTION';
export const DESTROY_PANEL_DATE_SUBSCRIPTION = 'dt/application/DESTROY_PANEL_DATE_SUBSCRIPTION';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const setActiveComponentId = (id) => ({
  type: SET_ACTIVE_COMPONENT_ID,
  id,
});

export const setActiveComponentFilter = (activeComponentFilter) => ({
  type: SET_ACTIVE_COMPONENT_FILTER,
  activeComponentFilter,
});

export const setPageType = (pageType) => ({
  type: SET_ACTIVE_PAGE_TYPE,
  pageType,
});

export const setZoomComponentId = (id) => ({
  type: SET_ZOOM_COMPONENT,
  id,
});

export const setActiveModelId = (modelId) => ({
  type: SET_ACTIVE_MODEL,
  modelId,
});

export const setViewerReady = (viewerReady) => ({
  type: SET_VIEWER_READY,
  viewerReady,
});

export const setTimezone = (timezone) => ({
  type: SET_TIMEZONE,
  timezone,
});

export const refreshData = (siteId) => ({
  type: REFRESH_VALUES,
  siteId,
});

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

// used internaly in getFilteredActiveComponentId in components bundle
// pt should always get activeComponent there (components bundle)
export const getActiveComponentId = (state) => state.application.activeComponentId;

export const getZoomComponentId = (state) => state.application.zoomComponentId;

export const getActiveModelId = (state) => state.application.activeModelId;

export const getViewerReady = (state) => state.application.viewerReady;

export const getPollingInterval = (state) => state.application.pollingInterval;

export const getPollingDateRange = createSelector(
  (state) => state.application.pollingDateRanges,
  (_, panelId) => panelId,
  (pollingDateRanges, panelId) => pollingDateRanges[panelId] || pollingDateRanges.global
);

export const getTimezone = (state) => state.application.timezone;

export const getActiveComponentFilter = (state) => state.application.activeComponentFilter;

export const getLastRefresh = (state) => state.application.refreshedAt;

export const getPollingActive = createSelector(
  [(state) => state.application.isPollingActive, (_, slice) => slice],
  (isPollingActive, slice) => {
    if (slice) {
      return !!isPollingActive[slice];
    }
    return Object.values(isPollingActive).some((part) => !!part);
  }
);

export const getPageType = (state) => state.application.pageType;
// Hooks

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doSetActiveComponentId(action) {
  const activeComponentId = yield select((state) => state.application.activeComponentId);

  // Resets to actual root component (null) if 'selecting' already existing root
  yield put({
    type: RECEIVE_ACTIVE_COMPONENT,
    activeComponentId: action.id === activeComponentId ? null : action.id,
  });

  updateUrlQuery({ activeComponent: action.id === activeComponentId ? undefined : action.id });
}

function doReceivePollingDateRange(action) {
  const {
    pollingDateRange: { startDate: start, endDate: end },
    panelId,
  } = action;

  if (!panelId) {
    updateUrlQuery({
      startDate: start || undefined,
      endDate: end || undefined,
    });
  }
}

function* doReceiveRefresh() {
  yield put({
    type: RECEIVE_REFRESH,
    refreshedAt: Date.now(),
  });
}

export const sagas = [
  takeLatest(SET_ACTIVE_COMPONENT_ID, doSetActiveComponentId),
  takeLatest(RECEIVE_POLLING_DATE_RANGE, doReceivePollingDateRange),
  debounce(500, REFRESH_VALUES, doReceiveRefresh),
];
