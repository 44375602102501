const notifications = {
  example: {
    /*
     * The following are notification fields which include sample values,
     * as well as any options which they should use (commented after), and a short explanation.
     */
    type: 'persistent', // ['persistent', 'transient(default)', 'actionable']
    status: 'error', // ['info(default)', success', 'warning', 'error'] error status reserved for system critical errors
    title: 'some bold title', // title which shows most prominently
    message: 'some message', // main message text
    extra: 'some extra text', // some sub text, displayed smaller
    id: 'some-error', // if defined, will replace previous notifications with same id, ootherwise random unique is generated
    groupId: 'some-common-id', // if defined will cause all notifications with same groupId to be displayed as a group
  },

  /*
   * QUESTIONS / COMMENTS
   * -----------------------------------------------------------------------------------------------
   * - Many notifications do not have defined messages. We should NOT present the user with the full
   *   error, but how/what should we relay to them? Do we need to give details about the error?
   *   Should we go through endpoints and ensure that we have a standardized Error stucture that we
   *   can get a message from (e.g. Error.message)?
   *
   * - Some messages have been removed as after the action the notification should not need much
   *   info (i.e. it's apparent what entity the notification is for, so a message is not necessary).
   *
   * - In general, notifications for entities that can have bulk actions performed have been given
   *   groupIds, others that might be recurring but are not specific have been given ids based on
   *   the action, while notifications for specific entities use the entity's id.
   */

  // bundles/ad
  // -----------------------------------------------------------------------------------------------

  getOneUser: {
    success: () => ({}), // none - used internally / displayed as badge (events)
    error: () => ({
      title: 'Failed to retrieve user',
      status: 'warning',
      groupId: 'get-user',
    }),
  },

  getUsers: {
    success: () => ({}), // none - used internally
    error: () => ({
      title: 'Failed to retrieve users',
      type: 'persistent',
      status: 'warning',
      groupId: 'get-users',
    }),
  },

  // bundles/auth
  // -----------------------------------------------------------------------------------------------

  getGrants: {
    success: () => ({}), // none - internally used
    error: () => ({}), // none - should redirect to NoGrantsView
  },

  getMemberTypes: {
    success: () => ({}), // none - internally used
    error: () => ({
      title: 'Failed to fetch member types',
      type: 'persistent',
      message: 'Updating member permissions will not be possible',
      status: 'warning',
      id: 'get-member-types',
    }),
  },

  getMembers: {
    success: () => ({}), // none - rendered as list
    error: () => ({
      title: 'Failed to fetch members',
      type: 'persistent',
      message: 'Site members will not be available',
      status: 'warning',
      id: 'get-members',
    }),
  },

  createMember: {
    success: () => ({}), // none - added member is displayed on creation
    error: () => ({
      title: 'Failed to add site member',
      type: 'persistent',
      status: 'warning',
      id: 'add-member',
    }),
  },

  updateMember: {
    success: (userId) => ({
      title: 'Permissions successfully updated',
      status: 'success',
      id: userId,
    }),
    error: () => ({
      title: 'Failed to update permissions',
      type: 'persistent',
      status: 'warning',
      id: 'update-member',
    }),
  },

  deleteMember: {
    success: (memberId) => ({
      title: 'Role successfully removed',
      status: 'success',
      id: memberId,
    }),
    error: () => ({
      title: 'Failed to remove role',
      type: 'persistent',
      status: 'warning',
      id: 'remove-member',
    }),
  },

  deleteMemberConnection: {
    success: () => ({}), // none - member/role is removed from display
    error: () => ({
      title: 'Failed to remove role',
      type: 'persistent',
      status: 'warning',
      id: 'remove-member-connection',
    }),
  },

  deleteUser: {
    success: (userId) => ({
      title: 'User successfully removed',
      status: 'success',
      id: userId,
    }),
    error: () => ({
      title: 'Failed to remove user',
      type: 'persistent',
      status: 'warning',
      id: 'remove-member',
    }),
  },

  createInvitations: {
    success: (count) => ({
      title: `${count} invitation(s) successfully created`,
      status: 'success',
      id: 'add-invitations',
    }),
    error: () => ({
      title: 'Failed to invite user',
      type: 'persistent',
      status: 'warning',
      id: 'add-invitations',
    }),
  },

  updateInvitation: {
    success: (invitationId) => ({
      title: 'Invitation successfully updated',
      status: 'success',
      id: invitationId,
    }),
    error: () => ({
      title: 'Failed to update invitation',
      type: 'persistent',
      status: 'warning',
      id: 'update-invitation',
    }),
  },

  updateInvitations: {
    success: () => ({
      title: 'Invitations successfully updated',
      status: 'success',
      id: 'update-invitations',
    }),
    error: () => ({
      title: 'Failed to update invitations',
      type: 'persistent',
      status: 'warning',
      id: 'update-invitations',
    }),
  },

  deleteInvitation: {
    success: () => ({}), // none - invitation is removed from display
    error: () => ({
      title: 'Failed to remove invitation',
      type: 'persistent',
      status: 'warning',
      id: 'remove-invitation',
    }),
  },

  authorizeUser: {
    success: () => ({}), // none - user receives permissions, enters site
    error: () => ({
      title: 'Failed to authorize user',
      type: 'persistent',
      status: 'error',
      id: 'authorize-user',
    }),
  },

  // bundles/files
  // -----------------------------------------------------------------------------------------------

  deleteFile: {
    success: (fileName) => ({
      title: 'File deleted successfully',
      message: fileName,
      status: 'success',
    }),
    error: (fileName, fileId) => ({
      title: 'Failed to delete file',
      message: fileName,
      type: 'persistent',
      status: 'warning',
      id: fileId,
    }),
  },

  getFiles: {
    success: () => ({}), // none - used internally / files displayed
    error: () => ({
      title: 'Failed to fetch files',
      type: 'persistent',
      status: 'warning',
      id: 'get-files',
    }),
  },

  createFile: {
    pre: (description, fileId) => ({
      title: description,
      message: '',
      id: fileId,
    }),
    success: (fileName, fileId) => ({
      title: 'File upload completed',
      message: fileName,
      status: 'success',
      id: fileId,
    }),
    size: (uploaded, total) => ({
      title: `${uploaded} out of ${total} documents have been uploaded successfully`,
      message: '',
      status: 'success',
    }),
    error: (fileName, fileId) => ({
      title: 'File upload failed!',
      message: fileName,
      type: 'persistent',
      status: 'warning',
      id: fileId,
    }),
  },

  // called when creating/updating files
  filesCreateTag: {
    success: () => ({}), // none - part of file create/update flow
    error: (operation) => ({
      title: `Failed to ${operation} files`,
      message: 'Tag creation failed.',
      type: 'persistent',
      status: 'warning',
      id: `${operation}-files-tag-creation`,
    }),
  },

  updateFile: {
    pre: (fileName, fileId) => ({
      title: 'File update in progress',
      message: fileName,
      id: fileId,
    }),
    success: (fileName, fileId) => ({
      title: 'File updated successfully',
      message: fileName,
      status: 'success',
      id: fileId,
    }),
    error: (fileName, fileId) => ({
      title: 'Failed to update file',
      message: fileName,
      type: 'persistent',
      status: 'warning',
      id: fileId,
    }),
  },

  getZippedFiles: {
    success: () => ({}), // none - download starts
    partialSuccess: (numNotFound, listNotFound) => ({
      // some files missing
      title: 'Failed to download all files',
      message: `The following file${numNotFound > 1 ? 's were' : ' was'} not found`,
      extra: listNotFound,
      type: 'persistent',
      status: 'warning',
      id: 'file-download',
    }),
    error: () => ({
      title: 'Failed to download files',
      type: 'persistent',
      status: 'warning',
      id: 'file-download',
    }),
  },

  // bundles/layouts
  // --------------------------------------------------------------------------------------------

  saveLayout: {
    success: (layoutId) => ({
      title: 'Layout updated successfully',
      status: 'success',
      id: layoutId,
    }),
    error: (layoutId) => ({
      title: 'Failed to update layout',
      type: 'persistent',
      status: 'warning',
      id: layoutId,
    }),
  },

  // bundles/sources
  // -----------------------------------------------------------------------------------------------

  connectivity: {
    success: () => ({}), // none - used internally / displayed
    error: () => ({
      title: 'Failed to fetch source connectivity',
      type: 'persistent',
      status: 'warning',
      id: 'fetch-source-connectivity',
    }),
  },

  // bundles/projects
  // -----------------------------------------------------------------------------------------------

  exportProjectData: {
    success: () => ({
      title: 'Project data exported successfully',
      status: 'success',
      id: 'project-export-data',
    }),
    error: () => ({
      title: 'Failed to export project data',
      type: 'persistent',
      status: 'warning',
      id: 'project-export-data',
    }),
  },

  downloadZipProject: {
    success: () => ({
      title: 'Project ZIP-file downloaded successfully',
      status: 'success',
      id: 'project-export-data',
    }),
    error: () => ({
      title: 'Failed to download project ZIP-file',
      type: 'persistent',
      status: 'warning',
      id: 'project-export-data',
    }),
  },

  createMimsProject: {
    success: (projectName) => ({
      title: `Successfully created MIMS project: ${projectName}`,
      status: 'success',
      type: 'persistent',
    }),
    error: () => ({
      title: 'Failed to create MIMS project',
      status: 'warning',
      type: 'persistent',
      id: 'createMimsProject',
    }),
  },

  createEcProject: {
    success: (projectName) => ({
      title: `Successfully created MIMS + EC project: ${projectName}`,
      status: 'success',
      type: 'persistent',
    }),
    error: () => ({
      title: 'Failed to create MIMS + EC project ',
      status: 'warning',
      type: 'persistent',
      id: 'createEcProject',
    }),
  },

  updateProject: {
    success: (projectName) => ({
      title: `Successfully updated project: ${projectName}`,
      status: 'success',
      type: 'persistent',
    }),
    error: () => ({
      title: 'Failed to update project',
      status: 'warning',
      type: 'persistent',
      id: 'updateProject',
    }),
  },

  deleteProject: {
    success: (projectName) => ({
      title: `Successfully deleted project: ${projectName}`,
      status: 'success',
      type: 'persistent',
    }),
    error: () => ({
      title: 'Failed to delete project',
      status: 'warning',
      type: 'persistent',
      id: 'deleteProject',
    }),
  },

  downloadMimsConfig: {
    success: (projectName) => ({
      title: `Successfully downloaded MIMS project: ${projectName}`,
      status: 'success',
      type: 'persistent',
    }),
    error: () => ({
      title: 'Failed to download MIMS project',
      status: 'warning',
      type: 'persistent',
      id: 'downloadMimsConfig',
    }),
  },

  downloadEcConfig: {
    success: (projectName) => ({
      title: `Successfully downloaded EC project: ${projectName}`,
      status: 'success',
      type: 'persistent',
    }),
    error: () => ({
      title: 'Failed to download EC project',
      status: 'warning',
      type: 'persistent',
      id: 'downloadEcConfig',
    }),
  },

  // components/EventModal/CommentForm/CommentForm.js
  // -----------------------------------------------------------------------------------------------
  // From shared (above): getComments, addEventFile, getOneUserLimited

  /*
   * This call handles including event files as related files in file preview.
   * NotFound errors are handled silently
   */
  getCommentFiles: {
    success: () => ({}), // none - displayed
    error: () => ({
      title: 'Failed to fetch comment files',
      type: 'persistent',
      status: 'warning',
      id: 'get-comment-files',
    }),
  },

  /*
   * Save comment chain: create > save added files > update
   * services calls to: createComment, createFile, updateComment
   * Single notification to handle any errors during save.
   */
  saveComment: {
    success: () => ({}), // none - displayed
    error: () => ({
      title: 'Failed to save comment',
      type: 'persistent',
      status: 'warning',
      id: 'save-event-comment',
    }),
  },

  deleteComment: {
    success: () => ({}), // none - removed
    error: () => ({
      title: 'Failed to delete comment',
      type: 'persistent',
      status: 'warning',
      id: 'delete-event-comment',
    }),
  },

  // components/ModelView/components/ModelEditModal/ModelEditModal.js,
  // components/panels/ViewerPanel/ViewerPanel.js
  // -----------------------------------------------------------------------------------------------

  getServiceToken: {
    success: () => ({}), // none
    error: () => ({}), // none - handled in viewer
  },

  // components/panels/EventTimelinePanel/EventTimelineState.js
  // --------------------------------------------------------
  // From shared (above): getIotEvents

  getUserEvents: {
    success: () => ({}), // none - displayed
    error: () => ({
      title: 'Failed to fetch user event data',
      type: 'persistent',
      status: 'warning',
      groupId: 'get-user-events',
    }),
  },

  // components/panels/EventTimelinePanel/TimelineChart.js,
  // components/panels/EventTimelinePanel/UserEventStatus.js
  // -----------------------------------------------------------------------------------------------
  // From shared (above): getComments

  // components/panels/TFRPanel/TFRPanel.js
  // -----------------------------------------------------------------------------------------------
  // From shared (above): getIotEvents

  // components/TagSelect/index.js
  // -----------------------------------------------------------------------------------------------
  // From bundles/tags (above): createTag

  // components/FilePreviewModal/FilePreviewModal.js
  // -----------------------------------------------------------------------------------------------

  filePreviewDownload: {
    success: () => ({}), // none - downloaded
    error: () => ({
      title: 'Failed to download file',
      type: 'persistent',
      status: 'warning',
      id: 'file-preview-download',
    }),
  },

  // components/ModelView/components/VersionColumnItem/VersionColumnItem.js
  // -----------------------------------------------------------------------------------------------
  // From bundles/uploads (above): uploadModel

  // model version upload (notfication handled outside bundle)
  modelVersionUpload: {
    success: () => ({
      title: 'New model version uploaded successfully',
      status: 'success',
      id: 'model-version-upload',
    }),
    error: () => ({
      title: 'Failed to upload new model version',
      type: 'persistent',
      status: 'warning',
      id: 'model-version-upload',
    }),
  },

  uploadAOExcel: {
    success: () => ({
      title: 'Imported Event Analyzer file successfully',
      status: 'success',
      id: 'ao-import-success',
    }),
    warning: () => ({
      title: 'File has invalid columns. Please check and upload again!',
      status: 'warning',
      type: 'persistent',
      id: 'ao-import-warning',
    }),
    error: () => ({
      title: 'Failed to import Event Analyzer file',
      status: 'warning',
      type: 'persistent',
      id: 'ao-import-failure',
    }),
  },
};

const getNotification = (type, status) => notifications[type][status];

export default getNotification;
