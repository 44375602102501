/* eslint-disable default-param-last */
import { createSelector } from 'reselect';

import { CLEAR_SITE_DATA } from './application';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const REQUEST_COMPONENTS = 'dt/components/REQUEST_COMPONENTS';
const RESET_COMPONENTS = 'dt/components/RESET_COMPONENTS';
const RECEIVE_COMPONENTS = 'dt/components/RECEIVE_COMPONENTS';
const RECEIVE_STATUS = 'dt/components/RECEIVE_STATUS';

const CREATE_COMPONENT_SUCCESS = 'dt/components/CREATE_COMPONENT_SUCCESS';

const COMPONENT_IMPORT_ERRORS = 'dt/components/COMPONENT_IMPORT_ERRORS';

const RECEIVE_REMOTE_ASSET_INFO = 'dt/components/RECEIVE_REMOTE_ASSET_INFO';

const SET_PAGE_COMPONENTS = 'dt/components/SET_PAGE_COMPONENTS';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestComponents = (siteId) => ({
  type: REQUEST_COMPONENTS,
  siteId,
});

export const receiveComponents = (...args) => {
  const [components, hashmap, componentTree, siteId, componentsImportedAt = 0] = args;
  return {
    type: RECEIVE_COMPONENTS,
    components,
    hashmap,
    componentTree,
    siteId,
    componentsUpdatedAt: Date.now(),
    componentsImportedAt,
  };
};

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  components: [],
  hashmap: {},
  remoteAssetInfo: {},
  siteLoaded: undefined,
  componentTree: {},
  componentsStatus: [],
  componentsUpdatedAt: 0,
  componentsImportedAt: 0,
  createdComponent: {},
  importErrors: [],
  pageComponents: [],
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_COMPONENTS: {
      return {
        ...state,
        components: action.components,
        hashmap: action.hashmap,
        componentTree: action.componentTree,
        siteLoaded: action.siteId,
        componentsUpdatedAt: action.componentsUpdatedAt,
        componentsImportedAt: action.componentsImportedAt,
      };
    }
    case RECEIVE_REMOTE_ASSET_INFO: {
      const { componentId, assetInfo } = action;
      return {
        ...state,
        remoteAssetInfo: {
          ...state.remoteAssetInfo,
          [componentId]: assetInfo,
        },
      };
    }
    case RECEIVE_STATUS: {
      return { ...state, componentsStatus: action.componentsStatus };
    }
    case CREATE_COMPONENT_SUCCESS: {
      return { ...state, createdComponent: { id: action.id, parent: action.parent } };
    }
    case SET_PAGE_COMPONENTS: {
      return { ...state, pageComponents: action.pageComponents };
    }
    case RESET_COMPONENTS:
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        components: [],
        hashmap: {},
        siteLoaded: undefined,
        componentTree: {},
        componentsStatus: [],
        componentsUpdatedAt: 0,
        componentsImportedAt: 0,
        createdComponent: {},
        remoteAssetInfo: {},
        importErrors: [],
      };
    }
    case COMPONENT_IMPORT_ERRORS: {
      return { ...state, importErrors: action.errors };
    }
    default:
      return state;
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getComponentsLoaded = (state) => state.components.siteLoaded;

export const getStaticComponents = (state) => state.components.components;

export const getSiteComponent = createSelector(getStaticComponents, (components) =>
  components.find((component) => component.type === 'site')
);

export const sagas = [];
