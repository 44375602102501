/* eslint-disable default-param-last */
import { createSelector } from 'reselect';

import { CLEAR_SITE_DATA } from './application';
// import getNotification from './notification-defaults';
// import { displayNotification, checkOnline } from './notifications';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const REQUEST_SOURCES = 'dt/sources/REQUEST_SOURCES';
const RECEIVE_SOURCES = 'dt/sources/RECEIVE_SOURCES';

const DELETE_SOURCE = 'dt/sources/DELETE_SOURCE';
const UPDATE_SOURCE = 'dt/sources/UPDATE_SOURCE';
const CREATE_SOURCE = 'dt/sources/CREATE_SOURCE';
const IMPORT_SOURCE = 'dt/sources/IMPORT_SOURCE';

const RESET_SOURCES = 'dt/sources/RESET_SOURCES';

const RECEIVE_SYNC_HEALTH = 'dt/sources/RECEIVE_SYNC_HEALTH';

const REQUEST_CONNECTIVITY = 'dt/sources/REQUEST_CONNECTIVITY';
const RECEIVE_CONNECTIVITY = 'dt/sources/RECEIVE_CONNECTIVITY';

const REQUEST_EXTENDED_SITE_VARIABLES = 'dt/sources/REQUEST_EXTENDED_SITE_VARIABLES';
const RECEIVE_VARIABLE = 'dt/sources/RECEIVE_VARIABLE';
const RECEIVE_EXTENDED_VARIABLE = 'dt/sources/RECEIVE_EXTENDED_VARIABLE';
const REMOVE_VARIABLE = 'dt/sources/REMOVE_VARIABLE';
const RECEIVE_EXTENDED_SITE_VARIABLES = 'dt/sources/RECEIVE_EXTENDED_SITE_VARIABLES';
const CREATE_SOURCE_VARIABLE = 'dt/components/CREATE_SOURCE_VARIABLE';
const UPDATE_SOURCE_VARIABLE = 'dt/components/UPDATE_SOURCE_VARIABLE';
const DELETE_SOURCE_VARIABLE = 'dt/components/DELETE_SOURCE_VARIABLE';

const UPLOAD_AO_EXCEL = 'dt/components/UPLOAD_AO_EXCEL_SHEET';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestSources = ({ siteId, withVariables = true }) => ({
  type: REQUEST_SOURCES,
  siteId,
  withVariables,
});

export const receiveSources = (sources, sourceTypes, siteVariablesIndex, siteId) => ({
  type: RECEIVE_SOURCES,
  sources,
  sourceTypes,
  siteVariablesIndex,
  siteId,
});

export const receiveSyncStatus = (siteId, syncHealth) => ({
  type: RECEIVE_SYNC_HEALTH,
  siteId,
  syncHealth,
});

export const deleteSource = (siteId, sourceId) => ({
  type: DELETE_SOURCE,
  siteId,
  sourceId,
});

export const updateSource = (siteId, sourceId, data) => ({
  type: UPDATE_SOURCE,
  siteId,
  sourceId,
  data,
});

export const createSource = (data) => ({
  type: CREATE_SOURCE,
  data,
});

export const importSource = (siteId, sourceId, onComplete) => ({
  type: IMPORT_SOURCE,
  siteId,
  sourceId,
  onComplete,
});

export const resetSources = () => ({
  type: RESET_SOURCES,
});

export const requestExtendedSiteVariables = () => ({
  type: REQUEST_EXTENDED_SITE_VARIABLES,
});

export const receiveVariable = (variable) => ({
  type: RECEIVE_VARIABLE,
  variable,
});

export const receiveExtendedVariable = (extendedVariable) => ({
  type: RECEIVE_EXTENDED_VARIABLE,
  extendedVariable,
});

export const removeVariable = (variableId) => ({
  type: REMOVE_VARIABLE,
  variableId,
});

export const receiveExtendedSiteVariables = (extendedSiteVariablesIndex) => ({
  type: RECEIVE_EXTENDED_SITE_VARIABLES,
  extendedSiteVariablesIndex,
  extendedSiteVariablesUpdatedAt: Date.now(),
});

export const requestConnectivity = (siteId) => ({
  type: REQUEST_CONNECTIVITY,
  siteId,
});

export const receieveConnectivity = (sourceConnectivityTree, aggregatedConnectivity) => ({
  type: RECEIVE_CONNECTIVITY,
  sourceConnectivityTree,
  aggregatedConnectivity,
});

export const createVariable = (sourceId, data) => ({
  type: CREATE_SOURCE_VARIABLE,
  sourceId,
  data,
});

export const updateVariable = (sourceId, variableId, data) => ({
  type: UPDATE_SOURCE_VARIABLE,
  sourceId,
  variableId,
  data,
});

export const deleteVariable = (sourceId, variableId) => ({
  type: DELETE_SOURCE_VARIABLE,
  sourceId,
  variableId,
});

export const uploadAOExcel = (sourceId, file, siteId, org, onComplete) => ({
  type: UPLOAD_AO_EXCEL,
  sourceId,
  file,
  siteId,
  org,
  onComplete,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  sources: [],
  sourceTypes: [],
  siteVariablesIndex: {},
  extendedSiteVariablesIndex: {},
  injectableSourceTypes: ['elasticsearch'],
  importableSourceTypes: ['custom', 'dec'],
  syncedSourceTypes: ['dec'],
  siteLoaded: undefined,
  syncHealth: {},
  extendedSiteVariablesUpdatedAt: 0,
  sourceConnectivityTree: [],
  aggregatedConnectivity: {},
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_SOURCES: {
      return {
        ...state,
        sources: action.sources,
        sourceTypes: action.sourceTypes,
        siteVariablesIndex: action.siteVariablesIndex || state.siteVariablesIndex,
        siteLoaded: action.siteId,
      };
    }
    case RECEIVE_SYNC_HEALTH: {
      const { syncHealth, siteId } = action;

      return {
        ...state,
        syncHealth: {
          ...state.syncHealth,
          [siteId]: syncHealth,
        },
      };
    }
    case RECEIVE_VARIABLE: {
      return {
        ...state,
        siteVariablesIndex: {
          ...state.siteVariablesIndex,
          [action.variable.id]: action.variable,
        },
      };
    }
    case RECEIVE_EXTENDED_VARIABLE: {
      return {
        ...state,
        extendedSiteVariablesIndex: {
          ...state.extendedSiteVariablesIndex,
          [action.extendedVariable.id]: action.extendedVariable,
        },
      };
    }
    case REMOVE_VARIABLE: {
      const varIndexCopy = { ...state.siteVariablesIndex };
      const extendedVarIndexCopy = { ...state.extendedSiteVariablesIndex };
      delete varIndexCopy[action.variableId];
      delete extendedVarIndexCopy[action.variableId];
      return {
        ...state,
        siteVariablesIndex: varIndexCopy,
        extendedSiteVariablesIndex: extendedVarIndexCopy,
      };
    }
    case RECEIVE_EXTENDED_SITE_VARIABLES: {
      return {
        ...state,
        extendedSiteVariablesIndex: action.extendedSiteVariablesIndex,
        extendedSiteVariablesUpdatedAt: action.extendedSiteVariablesUpdatedAt,
      };
    }
    case RECEIVE_CONNECTIVITY: {
      return {
        ...state,
        sourceConnectivityTree: action.sourceConnectivityTree,
        aggregatedConnectivity: action.aggregatedConnectivity,
      };
    }
    case RESET_SOURCES:
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        sources: [],
        sourceTypes: [],
        injectableSourceTypes: ['elasticsearch'],
        importableSourceTypes: ['custom', 'dec'],
        syncedSourceTypes: ['dec'],
        siteLoaded: undefined,
        syncHealth: {},
        siteVariablesIndex: {},
        extendedSiteVariablesIndex: {},
        extendedSiteVariablesUpdatedAt: 0,
        sourceConnectivityTree: [],
        aggregatedConnectivity: {},
      };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getSourcesLoaded = (state) => state.sources.siteLoaded;

export const getSources = (state) => state.sources.sources;

export const getSourceTypes = (state) => state.sources.sourceTypes;

export const getInjectableSourceTypes = (state) => state.sources.injectableSourceTypes;

export const getImportableSourceTypes = (state) => state.sources.importableSourceTypes;

export const getSyncedSources = (state) => state.sources.syncedSourceTypes;

export const getSyncHealth = (state, siteId) => state.sources.syncHealth[siteId];

export const getSiteVariablesIndex = (state) => state.sources.siteVariablesIndex;

export const getExtendedSiteVariablesIndex = (state) => state.sources.extendedSiteVariablesIndex;

export const getSiteVariables = createSelector(getSiteVariablesIndex, (varIndex = {}) =>
  Object.values(varIndex)
);

export const getExtendedSiteVariables = createSelector(
  getExtendedSiteVariablesIndex,
  (varIndex = {}) => Object.values(varIndex)
);

export const getExtendedSiteVariablesUpdatedAt = (state) =>
  state.sources.extendedSiteVariablesUpdatedAt;

export const getSourceConnectivityTree = (state) => state.sources.sourceConnectivityTree;

export const getAggregatedConnectivity = (state) => state.sources.aggregatedConnectivity;

export const sagas = [];
