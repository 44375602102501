.site-detail-view-component {
  padding-left: 4rem;
}

.no-side-margin {
  padding-left: 0 !important;
}

.base-view {
  padding-left: 4rem;
  padding-top: 1px;
}
.base-view .loading-container {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.base-view .panel-display-menu {
  display: flex;
  overflow: hidden;
}
.base-view .panel-display-menu a {
  float: left;
  display: block;
  text-align: center;
  padding: 1.5rem 2rem;
  text-decoration: none;
  font-size: medium;
  border-bottom: 3px solid transparent;
}
.base-view .mims-config {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1rem 0.5rem;
  padding: 1rem;
  gap: 10px;
}
.base-view .mims-config span {
  display: flex;
  width: 100%;
}
.base-view .mims-config dt {
  width: 180px;
}

button {
  font-size: 0.5rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvQmFzZVZpZXdJb1RDbG91ZCIsInNvdXJjZXMiOlsiQmFzZVZpZXdJb1RDbG91ZC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7OztBQUtKO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0ICdAaXEvcmVhY3QtY29tcG9uZW50cy9kaXN0L2NvbG9ycy5zY3NzJztcblxuLnNpdGUtZGV0YWlsLXZpZXctY29tcG9uZW50IHtcbiAgcGFkZGluZy1sZWZ0OiA0cmVtO1xufVxuXG4ubm8tc2lkZS1tYXJnaW4ge1xuICBwYWRkaW5nLWxlZnQ6IDAgIWltcG9ydGFudDtcbn1cblxuLmJhc2UtdmlldyB7XG4gIHBhZGRpbmctbGVmdDogNHJlbTtcbiAgcGFkZGluZy10b3A6IDFweDtcblxuICAubG9hZGluZy1jb250YWluZXIge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogOTB2aDtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIH1cblxuICAucGFuZWwtZGlzcGxheS1tZW51IHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIG92ZXJmbG93OiBoaWRkZW47XG4gIH1cblxuICAucGFuZWwtZGlzcGxheS1tZW51IGEge1xuICAgIGZsb2F0OiBsZWZ0O1xuICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICBwYWRkaW5nOiAxLjVyZW0gMnJlbTtcbiAgICB0ZXh0LWRlY29yYXRpb246IG5vbmU7XG4gICAgZm9udC1zaXplOiBtZWRpdW07XG4gICAgYm9yZGVyLWJvdHRvbTogM3B4IHNvbGlkIHRyYW5zcGFyZW50O1xuICB9XG5cbiAgLm1pbXMtY29uZmlnIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiByb3c7XG4gICAgZmxleC13cmFwOiB3cmFwO1xuICAgIG1hcmdpbjogMXJlbSAuNXJlbTtcbiAgICBwYWRkaW5nOiAxcmVtO1xuICAgIGdhcDogMTBweDtcbiAgfVxuXG4gIC5taW1zLWNvbmZpZyBzcGFuIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIHdpZHRoOiAxMDAlO1xuICB9XG5cbiAgLm1pbXMtY29uZmlnIGR0IHtcbiAgICB3aWR0aDogMTgwcHg7XG4gIH1cblxufVxuXG5idXR0b24ge1xuICBmb250LXNpemU6IDAuNXJlbTtcbn1cbiJdfQ== */