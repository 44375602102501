import { combineReducers } from 'redux';

import { reducer as ad } from './bundles/ad';
import { reducer as auth } from './bundles/auth';
import { reducer as components } from './bundles/components';
import { reducer as layouts } from './bundles/layouts';
import { reducer as notifications } from './bundles/notifications';
import { reducer as sites } from './bundles/sites';
import { reducer as sources } from './bundles/sources';
import { reducer as systemMessages } from './bundles/systemMessages';
import { reducer as trails } from './bundles/trails';
import { reducer as variables } from './bundles/variables';
import { reducer as projects } from './bundles/projects';

const reducers = combineReducers({
  ad,
  auth,
  components,
  layouts,
  notifications,
  sites,
  sources,
  systemMessages,
  trails,
  variables,
  projects,
});

export default reducers;
