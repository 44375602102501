/* eslint-disable default-param-last */

import { CLEAR_SITE_DATA } from './application';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const PERSIST_LAST_SUBSCRIPTION = 'dt/variables/PERSIST_LAST_SUBSCRIPTION';
const ADD_LAST_SUBSCRIPTIONS = 'dt/variables/ADD_LAST_SUBSCRIPTIONS';
const REMOVE_LAST_SUBSCRIPTION = 'dt/variables/REMOVE_LAST_SUBSCRIPTION';
const REMOVE_LAST_SUBSCRIPTIONS = 'dt/variables/REMOVE_LAST_SUBSCRIPTIONS';

const FETCH_LAST_VARIABLE_DATA = 'dt/variables/FETCH_LAST_VARIABLE_DATA';
const RECEIVE_LAST_VALUES = 'dt/variables/RECEIVE_LAST_VALUES';

const VARIABLES_IMPORTED_AT = 'dt/variables/VARIABLES_IMPORTED_AT';
const SET_VARIABLES_LAST_POLLING = 'dt/variables/SET_VARIABLES_LAST_POLLING';

const DOWNLOAD_VARIABLES_TEMPLATE = 'dt/variables/DOWNLOAD_VARIABLES_TEMPLATE';
const UPLOAD_VARIABLES_EXCEL_SHEET = 'dt/variables/UPLOAD_VARIABLES_EXCEL_SHEET';
const VARIABLE_IMPORT_ERRORS = 'dt/variables/VARIABLE_IMPORT_ERRORS';
const FETCH_EXTENDED_SERIES_DATA = 'dt/variables/FETCH_EXTENDED_SERIES_DATA';
const STOP_FETCH_EXTENDED_SERIES_DATA = 'dt/variables/STOP_FETCH_EXTENDED_SERIES_DATA';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const addSubscriptions = (panelId, variableIds, subscriptionType = 'last') => {
  const type = subscriptionType === 'last' ? ADD_LAST_SUBSCRIPTIONS : 'undefined';
  return {
    type,
    panelId,
    variableIds,
  };
};

export const removeSubscriptions = (panelId, variableIds, subscriptionType = 'last') => {
  const type = subscriptionType === 'last' ? REMOVE_LAST_SUBSCRIPTIONS : 'undefined';
  return {
    type,
    panelId,
    variableIds,
  };
};

export const receiveLastValues = (values) => ({
  type: RECEIVE_LAST_VALUES,
  values,
});

export const refreshLastVariableData = () => ({
  type: FETCH_LAST_VARIABLE_DATA,
});

export const setVariablesImportedAt = (importedAt = 0) => ({
  type: VARIABLES_IMPORTED_AT,
  variablesImportedAt: importedAt,
});

export const downloadVariablesExcel = (site) => ({
  type: DOWNLOAD_VARIABLES_TEMPLATE,
  site,
});

export const uploadVariablesExcel = (file, siteId, org, template) => ({
  type: UPLOAD_VARIABLES_EXCEL_SHEET,
  file,
  siteId,
  org,
  template,
});

export const setImportErrors = (errors) => ({
  type: VARIABLE_IMPORT_ERRORS,
  errors,
});

export const fetchExtendedSeriesData = (
  { variables, aggregation, granularity, timeRange, startDate, endDate },
  callback
) => ({
  type: FETCH_EXTENDED_SERIES_DATA,
  variables,
  aggregation,
  interval: granularity,
  timeRange,
  startDate,
  endDate,
  callback,
});

export const stopFetchExtendedSeriesData = () => ({ type: STOP_FETCH_EXTENDED_SERIES_DATA });

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  variablesLastPolling: {},
  lastSubscriptions: {},
  lastData: {},
  importErrors: [],
  variablesImportedAt: 0,
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case PERSIST_LAST_SUBSCRIPTION: {
      const { variableId, panelId } = action;
      const currentSubscriptions = state.lastSubscriptions[variableId] || {};
      return {
        ...state,
        lastSubscriptions: {
          ...state.lastSubscriptions,
          [action.variableId]: {
            ...currentSubscriptions,
            [panelId]: true,
          },
        },
      };
    }
    case REMOVE_LAST_SUBSCRIPTION: {
      const { variableId, panelId } = action;
      const currentSubscriptions = state.lastSubscriptions[variableId] || {};
      delete currentSubscriptions[panelId];

      return {
        ...state,
        lastSubscriptions: {
          ...state.lastSubscriptions,
          [action.variableId]: currentSubscriptions,
        },
      };
    }
    case SET_VARIABLES_LAST_POLLING: {
      return {
        ...state,
        variablesLastPolling: {
          ...state.variablesPolling,
          ...action.data,
        },
      };
    }
    case RECEIVE_LAST_VALUES: {
      const newLastData = { ...state.lastData };

      action.values.forEach((value) => {
        newLastData[value.variable] = value.value;
      });

      return { ...state, lastData: newLastData };
    }
    case VARIABLES_IMPORTED_AT: {
      return {
        ...state,
        variablesImportedAt: action.variablesImportedAt,
      };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        variablesLastPolling: {},
        lastSubscriptions: {},
        lastData: {},
        importErrors: [],
        variablesImportedAt: 0,
        variablesWithStates: [],
      };
    }
    case VARIABLE_IMPORT_ERRORS: {
      return { ...state, importErrors: action.errors };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getLastVariableData = (state) => state.variables.lastData;

export const getVariablesImportedAt = (state) => state.variables.variablesImportedAt;

export const getImportErrors = (state) => state.variables.importErrors;

export const sagas = [];
