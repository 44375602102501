import React from 'react';
import TreeView, { flattenTree } from 'react-accessible-treeview';
import { Icon } from '@iq/react-components';
import './TreeView.scss';

const FoldableTreeView = ({
  data,
  expandedIds = [],
  setMachExpandedIds = () => {},
  setMimsExpandedIds = () => {},
  setEcExpandedIds = () => {},
}) => {
  const treeData = flattenTree(data);

  const handleClickLink = (e, element, handleSelect) => {
    e.stopPropagation();
    // Only for MACH tree
    if (element.metadata?.expandMims) {
      setMachExpandedIds(element.metadata.expandMach);
      setMimsExpandedIds(element.metadata.expandMims);
      setEcExpandedIds(element.metadata.expandEc);
    }
    handleSelect(e);
  };

  return (
    <TreeView
      data={treeData}
      className="foldable-tree-view"
      expandedIds={expandedIds}
      nodeRenderer={({
        element,
        getNodeProps,
        level,
        isBranch,
        isExpanded,
        handleExpand,
        handleSelect,
      }) => (
        <div
          {...getNodeProps({ onClick: () => {} })}
          style={{ paddingLeft: 20 * (level - 1) }}
          onClick={(e) => handleClickLink(e, element, handleSelect)}
        >
          {isBranch && (
            <ArrowIcon
              isOpen={isExpanded}
              onClick={(e) => {
                e.stopPropagation();
                handleExpand(e);
              }}
            />
          )}
          <span className="name">{element.name}</span>
        </div>
      )}
    />
  );
};

const ArrowIcon = ({ isOpen, onClick }) => {
  const position = isOpen ? 'down' : 'right';
  return (
    <Icon
      icon={`keyboard-arrow-${position}`}
      className="arrow"
      onClick={onClick}
    />
  );
};

export default FoldableTreeView;
