.data-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 2rem;
  margin-top: 2rem;
}
.data-container .column-wrapper {
  width: 33%;
  position: relative;
  float: left;
}
.data-container .column {
  padding: 20px 10px 10px 10px;
  margin-right: 10px;
  overflow: auto;
  border: 0.5px solid #ccc;
  height: 60vh;
}
.data-container .title {
  position: absolute;
  top: -14px;
  left: 5px;
  padding: 5px;
  background: #fff;
}

.dark-theme .data-container .title {
  color: #fff;
  background: #262626;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvQmFzZVZpZXdPdmVydmlldyIsInNvdXJjZXMiOlsiQmFzZVZpZXdPdmVydmlldy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFJSjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuZGF0YS1jb250YWluZXIge1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtc3RhcnQ7XG4gIHBhZGRpbmc6IDJyZW07XG4gIG1hcmdpbi10b3A6IDJyZW07XG5cbiAgLmNvbHVtbi13cmFwcGVyIHtcbiAgICB3aWR0aDogMzMlO1xuICAgIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgICBmbG9hdDogbGVmdDtcbiAgfVxuXG4gIC5jb2x1bW4ge1xuICAgIHBhZGRpbmc6IDIwcHggMTBweCAxMHB4IDEwcHg7XG4gICAgbWFyZ2luLXJpZ2h0OiAxMHB4O1xuICAgIG92ZXJmbG93OiBhdXRvO1xuICAgIGJvcmRlcjogMC41cHggc29saWQgI2NjYztcbiAgICBoZWlnaHQ6IDYwdmg7XG4gIH1cblxuICAudGl0bGUge1xuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgICB0b3A6IC0xNHB4O1xuICAgIGxlZnQ6IDVweDtcbiAgICBwYWRkaW5nOiA1cHg7XG4gICAgYmFja2dyb3VuZDogI2ZmZjtcbiAgfVxufVxuXG4uZGFyay10aGVtZSAuZGF0YS1jb250YWluZXIgLnRpdGxlIHtcbiAgY29sb3I6ICNmZmY7XG4gIGJhY2tncm91bmQ6ICMyNjI2MjY7XG59XG4iXX0= */